"use client";
import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  typography: {
    fontFamily: "inherit !important",
  },
  palette: {
    background: {
      default: "#F9F9F9",
    },
    primary: {
      main: "#0F0F31",
      gradient:
        "linear-gradient(90deg, #41B378 0%, #03A1AC 100%, rgb(0, 160, 176) 100%)",
      dark: "#0F0F31",
      contrastText: "#fff",
      activeMenuBgColor: "rgba(17, 165, 160, 0.09)",
      gradientBorder:
        "linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, #41B378 0%, #03A1AC 100%, rgb(0, 160, 176) 100%) border-box",
      gradientBorderActive:
        "linear-gradient(rgb(234 247 246), rgb(234 247 246)) padding-box, linear-gradient(90deg, #41B378 0%, #03A1AC 100%, rgb(0, 160, 176) 100%) border-box",
    },
    secondary: {
      main: "#00a0b0",
    },
    text: {
      primary: "#0F0F31",
      secondary: "#707070",
      white: "#ffff",
      underlineColor: "#00a0b0",
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#D0342C",
        "&$error": {
          color: "#D0342C",
        },
      },
    },

    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "#BEBCC2",
          opacity: 1,
          "&::after": {
            content: "*",
            color: "red",
          },
        },
      },
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
          fontWeight: "400",
          color: "#0F0F31",
          backgroundColor: "white",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.06)",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 990,
      lg: 1430,
      xl: 1800,
    },
  },
});
