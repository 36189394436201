import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/CssBaseline/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["darkTheme"] */ "/vercel/path0/src/app/theme/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/redux/provider.jsx");
